<template lang="pug">
.ea-vgas-game__list.d-flex.flex-wrap.justify-content-center.align-items-start
  ea-vgas-game-tile.page-mb-md-alt(
    v-for='game in gameList',
    :key='game.id',
    :game='game',
    :regions='regions',
    :region-id='regionId',
    :ended='ended',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )
</template>
<script>
import EaVgasGameTile from './EaVgasGameTile'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    gameList: {
      type: Array,
      required: true
    },
    regions: {
      type: Array,
      required: true
    },
    regionId: {
      type: String,
      required: true
    },
    ended: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    EaVgasGameTile
  }
}
</script>
