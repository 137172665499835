const apexLegends = {
  id: 'apex-legends',
  title: 'Apex Legends™ Champion Edition',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--apex-legends--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--apex-legends--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--apex-legends.png')
  }
}

const battlefield = {
  id: 'battlefield-2042',
  title: 'Battlefield 2042 Gold Edition',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--battlefield-2042--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--battlefield-2042--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--battlefield-2042.png')
  }
}

const madden = {
  id: 'madden-22',
  title: 'EA Sports™ Madden NFL 22 MVP Edition',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--madden-22--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--madden-22--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--madden-22.png')
  }
}

const fifa = {
  id: 'fifa-22',
  title: 'EA Sports™ FIFA 22 Ultimate Edition',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--fifa-22--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--fifa-22--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--fifa-22.png')
  }
}

const itTakesTwo = {
  id: 'it-takes-two',
  title: 'It Takes Two',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--it-takes-two--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--it-takes-two--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--it-takes-two.png')
  }
}

const knockoutCity = {
  id: 'knockout-city',
  title: 'Knockout City™',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--knockout-city--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--knockout-city--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--knockout-city.png')
  }
}

const theSims = {
  id: 'the-sims-4',
  title: 'The Sims™ 4',
  images: {
    esrb: require('../../assets/themes/ea-vgas/images/game--sims-4--esrb.png'),
    pegi: require('../../assets/themes/ea-vgas/images/game--sims-4--pegi.png'),
    default: require('../../assets/themes/ea-vgas/images/game--sims-4.png')
  }
}

export const globalManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]

export const americasManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'playstation', idToken: 'J2fheId' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'playstation', idToken: 'HPYDOWA' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'playstation', idToken: 'Fnbxrg0' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'playstation', idToken: 'F4GaWk0' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'playstation', idToken: 'O2Psy75' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'playstation', idToken: 'XZnLkf-' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'playstation', idToken: 'oydtwIP' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]

export const europeManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'playstation', idToken: '9cr8uEO' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'playstation', idToken: 'WPp2sFK' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'playstation', idToken: 'sc0GR0d' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'playstation', idToken: 'Og2-iXH' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'playstation', idToken: 'y2mfUzP' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'playstation', idToken: 'TMIee4h' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'playstation', idToken: 'W4ZRaqO' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]

export const asiaManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'playstation', idToken: 'mEWD4Ba' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'playstation', idToken: 'T5vgUYL' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'playstation', idToken: 'yH6yVdL' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'playstation', idToken: 'K0bpRnU' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'playstation', idToken: 'IRxvmrY' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'playstation', idToken: '6CE7equ' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]

export const japanManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'playstation', idToken: 'neCk0xL' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'playstation', idToken: '7Yq54fd' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'playstation', idToken: 'GKQIzno' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'playstation', idToken: 'tgo5Au9' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'playstation', idToken: 'pfmJE_o' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]

export const southKoreaManifest = [
  {
    ...apexLegends,
    giveaways: [
      { platform: 'steam', idToken: 'EWP4rGe' },
      { platform: 'origin', idToken: 'mh1KV--' },
      { platform: 'playstation', idToken: '3lx_Lq-' },
      { platform: 'xbox', idToken: 'FhslTCt' }
    ]
  },
  {
    ...battlefield,
    giveaways: [
      { platform: 'steam', idToken: 'n6wZqjT' },
      { platform: 'origin', idToken: 'SZrVFIm' },
      { platform: 'playstation', idToken: '_gAuxao' },
      { platform: 'xbox', idToken: '9rDY4DW' }
    ]
  },
  {
    ...madden,
    giveaways: [
      { platform: 'origin', idToken: 'n-jS-xv' },
      { platform: 'xbox', idToken: 'wZ02lFV' }
    ]
  },
  {
    ...fifa,
    alternates: [
      {
        title: 'EA Sports™ FIFA 22',
        platforms: ['playstation']
      }
    ],
    giveaways: [
      { platform: 'steam', idToken: '6Ys1XZI' },
      { platform: 'origin', idToken: 'EdKu6Oa' },
      { platform: 'playstation', idToken: 'WJj1RNZ' },
      { platform: 'xbox', idToken: '-mS2NEo' }
    ]
  },
  {
    ...itTakesTwo,
    giveaways: [
      { platform: 'steam', idToken: '4qWy9JR' },
      { platform: 'origin', idToken: '4DQUnGC' },
      { platform: 'playstation', idToken: 'e-UytK2' },
      { platform: 'xbox', idToken: 'nUmNamj' }
    ]
  },
  {
    ...knockoutCity,
    giveaways: [
      { platform: 'steam', idToken: 'OB-o7FM' },
      { platform: 'origin', idToken: 'GSBcT-J' },
      { platform: 'playstation', idToken: '5UHqtA7' },
      { platform: 'xbox', idToken: '6qA52DF' }
    ]
  },
  {
    ...theSims,
    giveaways: [
      { platform: 'steam', idToken: 'FYuz6oD' },
      { platform: 'origin', idToken: '-kF3jWG' },
      { platform: 'playstation', idToken: 'wbxSPzg' },
      { platform: 'xbox', idToken: 'aLK9VmD' }
    ]
  }
]
