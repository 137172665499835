<template lang="pug">
.ea-vgas-game-tile__list.w-100
  h3.ea-vgas-game-tile__title.giveaway-tile__title.text-left.mb-1.pl-1.fw-bold
    span.giveaway-tile__title-title.fs-normal.pr-3 {{ title }}
  .ea-vgas-game-tile__enter-list.d-flex.flex-column.mt-2.pl-1.w-100
    a.ea-vgas-game-tile__enter-list-item.text-left.d-block(
      v-for='g in giveawayList',
      :key='g.idToken',
      :href='giveawayLink(g.idToken)',
      :aria-label='giveawayAriaLabel(g)',
      target='_blank',
      @mousedown='trackEvent(gaEventAction(g))'
    )
      img(:src='logos[g.platform]', :alt='g.platform')
</template>
<script>
import startCase from 'lodash/startCase'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  data() {
    return {
      logos: {
        origin: require('../../../assets/images/ea/logo-origin.svg'),
        playstation: require('../../../assets/images/ea/logo-playstation.svg'),
        steam: require('../../../assets/images/ea/logo-steam.svg'),
        xbox: require('../../../assets/images/ea/logo-xbox.svg')
      }
    }
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    giveawayList: {
      type: Array,
      required: true
    },
    ended: {
      type: Boolean,
      required: false,
      default: false
    },
    regionId: {
      type: String,
      required: true
    }
  },
  methods: {
    giveawayLink(idToken) {
      return `${this.$helpers.giveawayUrl(idToken)}${this.ended ? '/ended' : ''}`
    },
    gaEventAction(giveaway) {
      let action = `to_giveaway__${this.game.id}__${giveaway.platform}`

      if (this.regionId) {
        action = `${action}__${this.regionId}`
      }

      return `${action}__${giveaway.idToken}`
    },
    giveawayAriaLabel(giveaway) {
      return `${startCase(giveaway.platform)} Giveaway`
    }
  }
}
</script>
