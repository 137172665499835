<template lang="pug">
page.ea-vgas(:ga-event-category='gaEventCategory', :ga-event-label='gaEventLabel')
  //- Header
  base-header(
    slot='header',
    :showHeaderLinks='false',
    :ga-event-category='gaEventCategory',
    :ga-event-label='gaEventLabel'
  )

  //- Content
  template(slot='content')
    //- Video section
    //- page-section.ea-vgas-section.ea-vgas-section--video(:fullWidth='true', align='center')
    //-   template(slot='content')
    //-     surf-video(
    //-       :src='videoSrc',
    //-       :auto-play='true',
    //-       :loop='true',
    //-       :cover-mode='true',
    //-       :show-mute-button='true',
    //-       :ga-event-category='gaEventCategory',
    //-       :ga-event-label='gaEventLabel'
    //-     )
    //-     i#jump-icon.icon.icon-md-alt.cursor-pointer(:class='$icons.chevronDown', @click='jumpToMain')

    //- Banner - when needed
    page-section.ea-vgas-section.ea-vgas-section--banner(:fullWidth='true', align='center', aria-hidden='true')
      template(slot='content')
        router-link.d-block.banner-img(
          :to='{ name: "ea_vgas" }',
          @click='trackEvent("to_region_selector")'
        )
        .banner-img-bg

    //- Main
    #main
      page-section.ea-vgas-section.ea-vgas-section--enter.page-py-md(align='center')
        template(slot='content')
          //- Content
          .page-mt-md
            h1.mb-1 {{ isEnded ? endedTitle : activeTitle }}
            .fs-lg(v-if='isEnded && endedIntro') {{ endedIntro }}
            .fs-lg(v-if='!isEnded && activeIntro') {{ activeIntro }}

          //- Countdown
          .page-mt-sm-alt.fs-sm-alt(v-if='!isEnded && !!baseGiveaway')
            countdown(:giveaway='baseGiveaway', :is-active='isActive')

          //- Change Region
          b-btn.has-icon.has-icon--left.page-mt-md(
            variant='ea-vgas-white',
            :to='{ name: "ea_vgas" }',
            aria-label='Change Region',
            @click='trackEvent("to_region_selector")'
          )
            i.icon(:class='$icons.mapPin')
            span.copy Change Region

          //- Game Giveaway List
          ea-vgas-game-list.page-mt-lg(
            :game-list='gameList',
            :ended='isEnded',
            :regions='regions',
            :region-id='regionId',
            :ga-event-category='gaEventCategory',
            :ga-event-label='gaEventLabel'
          )
</template>
<script>
import SurfVideo from '@/components/SurfVideo'
import EaVgasGameList from '../components/EaVgasGameList'
import eaVgasPageMixin from '../ea-vgas-page-mixin'

export default {
  name: 'EaVGAsRegion',
  mixins: [eaVgasPageMixin],
  data() {
    return {
      regionId: '',
      gameList: [],
      activeTitle: 'Enter to Win!',
      activeIntro: '',
      endedTitle: 'See the Winners',
      endedIntro: ''
    }
  },
  methods: {
    toRegionSelector() {
      this.trackEvent('to_region_selector')
      this.$router.push('/ea-vgas')
    }
  },
  components: {
    SurfVideo,
    EaVgasGameList
  }
}
</script>
