<template lang="pug">
.ea-vgas-game-tile.giveaway-tile.d-flex.flex-column.align-items-start
  .ea-vgas-game-tile-inner.page-px-xs.w-100
    .ea-vgas-game-tile__main.giveaway-tile__main
      .ea-vgas-game-tile__img.giveaway-tile__img.mb-1
        img(:src='imageSrc', :alt='game.title', :title='game.title', width='500', height='279')
    //- Primary List
    giveaway-list.ea-vgas-game-tile__primary(
      :game='game',
      :title='game.title',
      :giveaway-list='primaryList',
      :ended='ended',
      :region-id='regionId',
      :ga-event-category='gaEventCategory',
      :ga-event-label='gaEventLabel'
    )
    //- Alternates List
    template(v-if='hasAlternates')
      giveaway-list.ea-vgas-game-tile__alternates.mt-2(
        v-for='altGame in game.alternates'
        :key='altGame.title',
        :game='game',
        :title='altGame.title',
        :giveaway-list='getAltGiveawayList(altGame)',
        :ended='ended',
        :region-id='regionId',
        :ga-event-category='gaEventCategory',
        :ga-event-label='gaEventLabel'
      )
</template>
<script>
import GiveawayList from './EaVgasGameTileGiveawayList'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin],
  props: {
    game: {
      type: Object,
      required: true
    },
    regions: {
      type: Array,
      required: true
    },
    regionId: {
      type: String,
      required: true
    },
    ended: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    region() {
      return this.regions.find((r) => r.id === this.regionId)
    },
    imageSrc() {
      return this.game.images[this.region.imageStyle]
    },
    hasAlternates() {
      return !!this.game?.alternates
    },
    altPlatforms() {
      if (this.hasAlternates) {
        let altPlatforms = []

        this.game.alternates.forEach((g) => {
          altPlatforms = [...altPlatforms, ...g.platforms]
        })

        return altPlatforms
      }

      return []
    },
    primaryList() {
      if (this.hasAlternates) {
        return this.game.giveaways.filter((g) => !this.altPlatforms.includes(g.platform))
      }

      return this.game.giveaways
    }
  },
  methods: {
    getAltGiveawayList(altGame) {
      const altGiveawayList = []

      this.game.giveaways.forEach((g) => {
        if (altGame.platforms.includes(g.platform)) {
          altGiveawayList.push(g)
        }
      })

      return altGiveawayList
    }
  },
  components: {
    GiveawayList
  }
}
</script>
